import toastr from "toastr";
import sha1 from "./sha1";
import DOMPurify from 'dompurify';

import axios from './http-common'




const graphApiUrl = process.env.REACT_APP_GRAPH_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const dataApiUrl = process.env.REACT_APP_DATA_URL;

/**
 * Converts logVer_1 to 1 if needed
 * @param {string} id
 */
function id(id) {
    if (id.indexOf("_") !== -1) {
        return id.split("_")[1];
    } else {
        return id;
    }
}

/**
 * @typedef DetectionResult
 * @prop {string} brandName
 * @prop {string} mediaId
 * @prop {string} mediaUrl
 * @prop {string} approved
 *
 * @typedef FeedDetection
 * @prop {string} name
 * @prop {number} area
 * @prop {number} id
 * @prop {string} iconUrl
 * @prop {number[]} coordinates
 * @prop {number} confidenceALE
 * @prop {string} type
 * @prop {string} size
 * @prop {number} area
 *
 * @typedef FeedDetectionResult
 * @prop {string} mediaUrl
 * @prop {string} previewUrl
 * @prop {string?} renderedMediaUrl
 * @prop {FeedDetection[]} detections
 * @prop {string} sessionId
 * @prop {number} status
 * @prop {string} timeCreated
 * @prop {string} meta
 * @prop {object?} qa
 * @prop {number} progress
 * @prop {object?} review
 */

export default class DataService {
    static getImagesUploadedDaily(
        dateBegin,
        dateEnd,
        developerId,
        hasDetections,
        minScore
    ) {
        return axios
            .get(dataApiUrl + "/api-dashboard/redshift/imagesUploadedDaily", {
                params: {
                    dateBegin: dateBegin.format("YYYY-MM-DD"),
                    dateEnd: dateEnd.format("YYYY-MM-DD"),
                    developerId: developerId,
                    hasDetections: hasDetections,
                    minScore: minScore,
                    version: 2
                }
            })
            .then(response => {
                return response.data;
            })
            .catch(message => {
                console.error("XHR Failed for getImagesUploadedDaily", message);
            });
    }

    static getLimits(dateBegin, dateEnd) {
        return axios
            .get(apiUrl + "/limits", {
                params: {
                    dateBegin: dateBegin.format("YYYY-MM-DD"),
                    dateEnd: dateEnd.format("YYYY-MM-DD"),
                    period: "day",
                    companyUsage: 1,
                    version: 2
                },
                cache: true
            })
            .then(response => {
                return response.data;
            })
            .catch(message => {
                console.error("XHR Failed for getLimits", message);
            });
    }

    static getActiveLogoCount(developerId, beforeDate) {
        return axios
            .get(
                graphApiUrl +
                "/detectionSetting.eq(developerId," +
                developerId +
                ")/" +
                "detectionSettingVisualClassGroup/" +
                "visualClassGroup/" +
                "visualClassGroupMember.eq(status, 1).before(dateUpdated," +
                beforeDate.format("YYYY-MM-DD") +
                ")/" +
                "logoVersion/" +
                "brand",
                {
                    params: {
                        agg: "count",
                        version: 2
                    }
                }
            )
            .then(response => {
                return response.data;
            })
            .catch(message => {
                console.error("XHR Failed for getActiveLogoCount", message);
            });
    }

    static getBrandDetectionCount(dateBegin, dateEnd, developerId, minScore) {
        return axios
            .get(dataApiUrl + "/api-dashboard/redshift/brandDetectionCount", {
                params: {
                    dateBegin: dateBegin.format("YYYY-MM-DD"),
                    dateEnd: dateEnd.format("YYYY-MM-DD"),
                    developerId,
                    minScore,
                    version: 2
                },
                cache: true
            })
            .then(response => {
                return response.data;
            })
            .catch(message => {
                console.error("XHR Failed for getBrandDetectionCount", message);
            });
    }

    static getSummaryLimits(dateBegin, dateEnd, minScore) {
        return axios
            .get(apiUrl + "/limits/summary", {
                params: {
                    dateBegin: dateBegin.format("YYYY-MM-DD"),
                    dateEnd: dateEnd.format("YYYY-MM-DD"),
                    minConfidence: minScore,
                    version: 2
                },
                cache: true
            })
            .then(response => {
                return response.data;
            })
            .catch(message => {
                console.error("XHR Failed for getSummaryLimits", message);
            });
    }

    static getIds(ids, fields) {
        return axios
            .get(graphApiUrl + "/", {
                params: {
                    ids: ids.toString(),
                    fields: fields ? fields.toString() : null,
                    version: 2
                },
                cache: true
            })
            .then(response => {
                return response.data;
            })
            .catch(message => {
                this.exception.catcher("XHR Failed for getIds")(message);
            });
    }

    static getMe() {
        return axios
            .get(apiUrl + "/me", {
                params: {
                    product: "apimonitor"
                }
            })
            .then(response => {
                response.data = response.data.data;
                if (
                    !response.data.developer.meta ||
                    !response.data.developer.meta.firehose ||
                    !response.data.developer.meta.firehose.apiMonitor
                ) {
                    Object.assign(response.data.developer, {
                        meta: {
                            firehose: {
                                apiMonitor: {}
                            }
                        }
                    });
                }

                if (response.data.developer.meta.firehose) {
                    var fh = response.data.developer.meta.firehose.apiMonitor;
                    fh.dateRange = fh.dateRange || {};
                }

                return response.data;
            })
            .catch(message => {
                console.error("XHR Failed for getMe", message);
            });
    }

    static updateDeveloperMeta(apiMonitor) {
        return this.getMe().then(data => {
            Object.assign(data.developer.meta.firehose.apiMonitor, apiMonitor);
            return axios
                .post(
                    apiUrl + "/me",
                    "meta=" + encodeURI(JSON.stringify(data.developer.meta)),
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    }
                )
                .catch(message => {
                    console.error("XHR Failed for updateDeveloperMeta", message);
                });
        });
    }

    /**
     *
     * @returns {Promise<{ brands: import("./EditBrandsMetaModal").Brand[] }>}
     */
    static getActiveBrands() {
        return axios
            .get(apiUrl + `/brands/active`)
            .then(response => this.clearMetaTags(response)).catch(message => {
                console.error("XHR Failed for getActiveBrands", message);
            });
    }

    static getAvailableBrands() {
        return axios
            .get(apiUrl + `/brands/available`)
            .then(response => this.clearMetaTags(response)).catch(message => {
                console.error("XHR Failed for getAvailableBrands", message);
            });
    }

    static clearMetaTags(brands) {
        var data = brands.data;
        data.brands.forEach((brand, brandIndex) => {
            brand.logoVersionArray.forEach((lv, lvIndex) => {
                var meta = lv.meta;
                if (meta) {
                    var cleanMeta = {}
                    Object.keys(meta).forEach(key => {
                        cleanMeta[DOMPurify.sanitize(key, {USE_PROFILES: {html: false}})] = DOMPurify.sanitize(meta[key], {USE_PROFILES: {html: false}});
                    })
                    data.brands[brandIndex].logoVersionArray[lvIndex].meta = cleanMeta;
                }
            })
        })
        return data
    }

    /**
     *
     * @param {{name: string, url: string}[]} brands
     */
    static activateBrands(brands) {
        return axios
            .post(
                apiUrl + "/brands/activate",
                "brands=" + encodeURIComponent(JSON.stringify(brands)),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
            .catch(({response}) => {
                const error = response.data.errorMessage || response;
                toastr.error(error, "XHR Failed for activateBrands");
                // Stops sequential .then from executing
                throw new Error(error);
            });
    }

    /**
     *
     * @param {string} mediaUrl
     * @param {string} mediaBase64
     * @param {object} meta
     * @param {string} name
     */
    static addImage(name, mediaUrl, mediaBase64, meta) {
        const dataArray = [];
        if (mediaUrl) {
            dataArray.push("mediaUrl=" + encodeURIComponent(mediaUrl));
        } else {
            dataArray.push("mediaBase64=" + encodeURIComponent(mediaBase64));
        }

        if (meta) {
            dataArray.push("meta=" + encodeURIComponent(JSON.stringify(meta)))
        }

        dataArray.push("name=" + encodeURIComponent(name));

        return axios
            .post(
                apiUrl + "/search/items",
                dataArray.join('&'),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
            .catch(({response}) => {
                const error = response.data.errorMessage || response;
                toastr.error(`Failed to upload an image named "${name}": ${error}`, "XHR Failed for addImage");
                // Stops sequential .then from executing
                throw new Error(error);
            });
    }


    static async enableCsvBrand({brandId, meta}) {
         return axios
            .post(
                apiUrl + `/brands/active/${brandId}`,
                !!meta ? "meta=" + encodeURIComponent(JSON.stringify(meta)) : null,
            )
             .then(response => {
                 return true;
             })
             .catch(({response}) => {
                 console.error({response})
                 return false;
             })
    }


    static enableBrand(brandId) {
        return axios
            .post(apiUrl + `/brands/active/${brandId}`)
            .then(response => response.data)
            .catch(({response}) => {
                if (response.status !== 401) {
                    toastr.error("XHR Failed for enableBrand");
                } else {
                    toastr.error(
                        "Could not activate brand: permission denied. You can request permission at support@visua.com"
                    );
                }
                // Stops sequential .then from executing
                return Promise.reject(response);
            });
    }

    /**
     *
     * @param {string} brandIds - A comma separated list of Brand Ids
     */
    static disableBrand(brandIds) {
        return axios
            .delete(apiUrl + `/brands/active/${brandIds}`)
            .then(response => response.data)
            .catch(({response}) => {
                if (response.status !== 401) {
                    toastr.error("XHR Failed for disableBrand");
                } else {
                    toastr.error(
                        "Could not deactivate brand: permission denied. You can request permission at support@visua.com"
                    );
                }
                // Stops sequential .then from executing
                return Promise.reject(response);
            });
    }

    static enableLogoVersion(brandId, logoVersionId) {
        return axios
            .post(apiUrl + `/brands/active/${brandId}/logoVersions/${logoVersionId}`)
            .then(response => response.data)
            .catch(({response}) => {
                if (response.status !== 401) {
                    toastr.error("XHR Failed for enableLogoVersion");
                } else {
                    toastr.error(
                        "Could not activate logo version: permission denied. You can request permission at support@visua.com"
                    );
                }
                // Stops sequential .then from executing
                return Promise.reject(response);
            });
    }

    static disableLogoVersion(brandId, logoVersionId) {
        return axios
            .delete(
                apiUrl + `/brands/active/${brandId}/logoVersions/${logoVersionId}`
            )
            .then(response => response.data)
            .catch(({response}) => {
                if (response.status !== 401) {
                    toastr.error("XHR Failed for enableLogoVersion");
                } else {
                    toastr.error(
                        "Could not deactivate logo version: permission denied. You can request permission at support@visua.com"
                    );
                }
                // Stops sequential .then from executing
                return Promise.reject(response);
            });
    }

    static getBrandRequestGroupStatus() {
        return axios
            .get(apiUrl + "/brands/activate/status")
            .then(response => response.data)
            .catch(message => {
                toastr.error(message, "XHR Failed for getBrandRequestGroupStatus");
            });
    }

    /**
     *
     * @param {{[key: string]: string}} meta
     */
    static putLogoVersionMeta(brandId, logoVersionId, meta) {
        return axios
            .put(
                apiUrl + `/brands/active/${brandId}/logoVersions/${logoVersionId}/meta`,
                "meta=" + encodeURIComponent(JSON.stringify(meta)),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
            .catch(({response}) => {
                const error = response.data.errorMessage || response;
                toastr.error(error, "XHR Failed for putLogoVersionMeta");
                // Stops sequential .then from executing
                throw new Error(error);
            });
    }

    static putBrandMeta(brandId, meta) {
        return axios
            .put(
                apiUrl + `/brands/active/${brandId}/meta`,
                "meta=" + encodeURIComponent(JSON.stringify(meta)),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
            .catch(({response}) => {
                const error = response.data.errorMessage || response;
                toastr.error(error, "XHR Failed for putBrandMeta");
                // Stops sequential .then from executing
                throw new Error(error);
            });
    }

    /**
     *
     * @param {{detSetIds?: string, fields?: string}} param0
     */
    static getBrands({detSetIds, fields}) {
        return axios
            .get(graphApiUrl + `/detectionSetting.eq(id,${detSetIds})/brand`, {
                params: {
                    fields,
                    version: 2
                }
            })
            .then(response => {
                return response.data;
            })
            .catch(message => {
                toastr.error(message, "XHR Failed for getBrands");
            });
    }

    /**
     *
     * @param {number[]} mediaIds
     * @param {string} minScore
     * @returns {Promise<Partial<DetectionResult>[]>}
     */
    static getDetections(mediaIds, minScore, groupByMedia = true) {
        var par = {
            mediaIds: mediaIds.toString(),
            transpose: false,
            version: 2
        };
        if (minScore !== "ALL") {
            par.minScore = minScore;
        }

        return axios
            .get(dataApiUrl + "/api-dashboard/rds/detections", {
                params: par
            })
            .then(response => {
                if (groupByMedia) {
                    const detections = response.data;
                    const groupedDetections = {};

                    detections.forEach(detection => {
                        detection.logoVersionThumbUrl = this.getThumbUrl(
                            detection.logoVersionId
                        );
                        detection.dets_bb = JSON.parse(detection.dets_bb);
                        if (groupedDetections[detection.mediaId]) {
                            groupedDetections[detection.mediaId].push(detection);
                        } else {
                            groupedDetections[detection.mediaId] = [detection];
                        }
                    });

                    return groupedDetections;
                } else {
                    return response.data;
                }
            })
            .catch(message => {
                toastr.error(message, "XHR Failed for amExplode");
                return {};
            });
    }

    static postDetectMultiple(mediaUrlsFile) {
        let formData = new FormData();
        formData.append("mediaUrlsFile", mediaUrlsFile);

        return axios
            .post(apiUrl + "/detect/multiple", formData, {
                headers: {
                    "Content-Type": undefined
                }
            })
            .catch(message => {
                const error = message.data.errorMessage || message;
                toastr.error(error, "XHR Failed for detectMultiple");
            });
    }


    static postDetectMultipleJson(mediaCsvData) {
        return axios
            .post(apiUrl + "/detect/multiple",
                "media=" + encodeURIComponent(JSON.stringify(mediaCsvData).replace(/'/g, "%27")),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
            .catch(message => {
                const error = message.data.errorMessage || message;
                toastr.error(error, "XHR Failed for detectMultiple");
            });
    }

    static getThumbUrl(logoVersionId) {
        var extension = ".png",
            filekey =
                "logograb/cache-dir-local/logo-version/" +
                logoVersionId +
                "/thumb" +
                extension;

        return (
            "https://s3-eu-west-1.amazonaws.com/s3.logograb.com/logograb/cache-dir-public/" +
            sha1(filekey) +
            extension
        );
    }

    /**
     *
     * @param {{
     * dateBegin: moment.Moment,
     * dateEnd: moment.Moment,
     * brandIds?: string[],
     * minScore?: string,
     * filterValue?: string,
     * filterKey?: string,
     * page?: number,
     * explore?: boolean,
     * desc?: boolean,
     * reviewStatus?: string,
     * developersHash?: any,
     * pageSize?: number }} param0
     * @returns {Promise<{ feed: FeedDetectionResult[], mediaCount: number }>}
     */
    static getFeed({
                       dateBegin,
                       dateEnd,
                       brandIds,
                       minScore,
                       page,
                       pageSize,
                       explore,
                       desc,
                       filterValue,
                       filterKey,
                       reviewStatus,
                       developersHash
                   }) {
        const params = {
            ...(dateBegin && {dateBegin: dateBegin.format("YYYY-MM-DD")}),
            ...(dateEnd && {dateEnd: dateEnd.format("YYYY-MM-DD")}),
            brandIds: brandIds && brandIds.map(brandId => id(brandId)).join(","),
            minScore: minScore !== "ALL" ? minScore : undefined,
            page,
            pageSize,
            explore,
            desc,
            filterValue,
            filterKey,
            reviewStatus,
            developersHash,
        };

        Object.keys(params).forEach(key => {
            if (params[key] === undefined || params[key].length === 0) {
                delete params[key];
            }
        });

        return axios
            .get(apiUrl + "/detect/feed", {
                params
            })
            .then(response => {
                if (response.status === 204) {
                    return {feed: []}
                } else {
                    if (!response.data.data.feed) {
                        response.data.data.feed = [];
                    }

                    response.data.data.feed.forEach(item => {
                        item.mediaUrl = item.mediaUrl.split(" ").join("%20")
                    })

                    response.data.data['total'] = response.headers["x-total-count"];

                    return response.data.data
                }
            })
            .catch(error => {
                let errorMessage = error.message;
                try {
                    errorMessage = error.response.data.errorMessage;
                } catch {
                }

                toastr.error(errorMessage, "XHR Failed for getFeed");
                return {feed: []};
            });
    }

    static detect(sessionId) {
        return axios.get(apiUrl + `/detect/${sessionId}/response`).catch(error => {
            let errorMessage;
            try {
                errorMessage = error.response.data.errorMessage;
            } catch {
            }
            toastr.error(errorMessage, "XHR Failed for detect");
            throw error;
        })
    }

    static detectExplore(sessionId) {
        return axios.put(apiUrl + `/detect/${sessionId}/explore`).catch(error => {
            let errorMessage;
            try {
                errorMessage = error.response.data.errorMessage;
            } catch {
            }
            toastr.error(errorMessage, "XHR Failed for detect");
            throw error;
        })
    }

    /**
     * @param {Object} filters
     * @param {string=} filters.dateEnd - yyyy-MM-dd
     * @param {number=} filters.pageSize
     * @param {number=} filters.page
     * @param {string=} filters.meta
     */
    static getItems({dateEnd, pageSize = 20, page = 1, meta = '', ids = []} = {}) {
        let str = `?pageSize=${pageSize}&page=${page}`;
        if (dateEnd) str += `&dateEnd=${dateEnd}`
        if (meta) str += `&meta=${meta}`
        if (ids.length > 0) {
            ids.forEach((id) =>{
                if (!!id && id.length > 0) {
                    if (!isNaN(parseInt(id, 10))) {
                        str+=`&mediaDetectionIds=${id.trim()}`
                    }
                }
            } )
        }

        return axios.get(apiUrl + `/search/items${str}`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
        })
            .catch(() => {
                toastr.error("XHR Failed for getItems");
            })
    }

    static disableItem(itemId) {
        return axios
            .delete(apiUrl + `/search/items/${itemId}`)
            .then(response => response.data)
            .catch(({response}) => {
                if (response.status !== 401) {
                    toastr.error("XHR Failed for disableItem");
                } else {
                    toastr.error(
                        "Could not deactivate brand: permission denied. You can request permission at support@visua.com"
                    );
                }
                return Promise.reject(response);
            });
    }

    static putItemMeta(itemId, meta) {
        return axios
            .put(
                apiUrl + `/search/items/${itemId}/meta`,
                "meta=" + encodeURIComponent(JSON.stringify(meta)),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
            .catch(({response}) => {
                const error = response.data.errorMessage || response;
                toastr.error(error, "XHR Failed for putItemMeta");
                throw new Error(error);
            });
    }

    static getText() {
        return axios
            .get(apiUrl + `/text/items`)
            .then(response => {
                return response.data;
            })
            .catch(message => {
                toastr.error(message, "XHR Failed for getBrands");
            });
    }

    static addText(text, threshold) {
        return axios
            .post(
                apiUrl + `/text/items`,
                "text=" + encodeURIComponent(text) + "&threshold=" + encodeURIComponent(threshold),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
            .then(response => {
                return response.data;
            })
            .catch(({response}) => {
                const error = response.data.errorMessage || response;
                toastr.error(error);
            });
    }

    static putText(id, threshold) {
        return axios
            .put(
                apiUrl + `/text/items/${id}`,
                "status=1&threshold=" + encodeURIComponent(threshold),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
            .then(response => {
                return response.data;
            })
            .catch(message => {
                toastr.error(message, "XHR Failed for addText");
            });
    }

    static deleteText(id, hardDelete) {
        const deleteTextUrl = hardDelete ?
            `${apiUrl}/text/items/${id}?hardDelete=true` : `${apiUrl}/text/items/${id}`

        return axios
            .delete(deleteTextUrl)
            .then(response => {
                return response.data;
            })
            .catch(message => {
                toastr.error(message, "XHR Failed for addText");
            });
    }

    static getWorkflow() {
        return axios
            .get(apiUrl + "/workflows")
            .then(response => {
                return response.data;
            })
            .catch(message => {
                toastr.error(message, "XHR Failed for getWorkflow");
            });
    }

    static postWorkflow(workflow) {
        return axios
            .post(apiUrl + "/workflows",
                "workflow=" + encodeURIComponent(JSON.stringify(workflow)),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
            .catch(message => {
                toastr.error(message, "XHR Failed for postWorkflow");
            });
    }

    //
    //"text=" + encodeURIComponent(text) + "&threshold=" + encodeURIComponent(threshold),
    static sendBrandEmail(userData, csvFile, nBrands) {
        const userName = userData.developer?.name;
        const projectName = userData.detectionSetting?.service;
        const emailSubject = `Large brands activation from ${userName} - ${projectName}`
        const emailText = `Received an activation of ${nBrands} brands from ${userName} - ${projectName}`
        const formData = new FormData();
      formData.append("subject", emailSubject);
      formData.append("bodyText", emailText);
      formData.append("bodyHtml", emailText);
        formData.append("brands", csvFile);

        return axios
            .post(apiUrl + "/emails", formData,
                //`subject=${encodeURIComponent(emailSubject)}&bodyText=${encodeURIComponent(emailText)}&bodyHtml=${encodeURIComponent(emailText)}&${formData}`,
                {
                  headers: {
                    "Content-Type": undefined
                  }
                }
            )
            .then(res => {
                console.log({"/emails response": res})
            })
    }

    static addTextToBrand(text, threshold, brandId) {
        return axios
            .post(
                apiUrl + `/text/items`,
                `text=${encodeURIComponent(text)}&threshold=${parseFloat(encodeURIComponent(threshold))}&brandId=${parseInt(encodeURIComponent(brandId), 10)}`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
            .then(response => {
                return response.data;
            })
            .catch(({response}) => {
                const error = response.data?.errorMessage || response;
                toastr.error(error);
            });
    }

    static getIannData(type = 1) {
        return axios
            .get(apiUrl + `/detect/pipeline?pipelineStatus=${type}`)
            .then(response => {
                return response?.data?.iann ?? [];
            })
    }

    static changeIannPriority(hash, priority) {
        const dataToSend = new FormData();
        dataToSend.append('priority', String(priority));
        return axios
            .post(apiUrl + `/detect/pipeline/${hash}`,
                dataToSend,
                {
                    headers: {
                        'Content-Type': undefined,
                    },
                },
            )
            .then(response => {
                return response?.data?.iann ?? [];
            })
    }

    static deleteTextBrand(id) {
        return axios
            .delete(`${apiUrl}/text/items/${id}`,)
            .then(res => res?.data)
            .catch(({response}) => {
                const error = response.data?.errorMessage || response;
                toastr.error(error);
            });
    }

    static requestOutput(id) {
        return (
            axios.post(`/detect/pipeline/${id}/render`)
        )
    }
}

import * as React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import {useEffect, useRef, useState} from "react";
import PreviewCSVTable from "./PreviewCSVTable";
import DataService from "../../DataService";
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import DangerousIcon from '@mui/icons-material/Dangerous';

function ListOfUrls({data}) {
    return (
        <>
            <Typography
                variant={'h6'}
            >
                Media Urls ({data.length} Lines)
            </Typography>
            <List dense={true}>
                {
                    data.map(
                        (row, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                    borderBottom: "1px solid #f2f2f2",
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    marginRight: "1.5em",
                                                    background: "#f5f5f5",
                                                    width: "4em",
                                                }}
                                            >
                                                {index + 1}
                                            </Box>
                                            <Box>
                                                {row}
                                            </Box>
                                        </Box>
                                    }
                                />
                            </ListItem>
                        )
                    )}
            </List>
        </>
    )
}


function DialogTextContent(
    {
        isLoading,
        isError,
        text,
    }
) {
    return (
        <Box
            sx={{
                margin: '3em auto',
                textAlign: 'center',
            }}
        >
            <Typography
                variant={'h3'}
            >
                Upload completed!
            </Typography>
        </Box>
    )
}


function PreviewCSVModal(
    {
        open = false,
        handleClose,
        isLoading = false,
        csvData = [],
        detectMultipleValidation = [],
    }
) {


    const [csvType, setCsvType] = useState('list')
    const [error, setError] = useState(false);
    const [header, setHeader] = useState([]);
    const [rowCsv, setRowCsv] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [uploadCompleted, setUploadCompleted] = useState(false);
    const containerTable = useRef(null);
    const [errorText, setErrorText] = useState('');

    const isNaNValue = (string) => {
        const LowerStr = string.toLowerCase().trim();
        return LowerStr === 'n/a' || LowerStr === 'na'
    }

    const validateField = (value, validation, opt) => {
        if (isNaNValue(value)) return true;

        if (validation === "hh:mm:ss") {
            const arrValues = value.split(':');
            if (arrValues.length !== 3) return false;

            if (
                ![1, 2].includes(arrValues[0].length)
                || arrValues[1].length !== 2
                || arrValues[2].length !== 2
            ) {
                return false;
            }

            if (
                isNaN(arrValues[0])
                || isNaN(arrValues[1])
                || isNaN(arrValues[2])
            ) {
                return false;
            }

            const numValues = arrValues.map(el => parseInt(el, 10))

            return !(numValues[0] < 0
                || (numValues[1] < 0 || numValues[1] > 59)
                || (numValues[2] < 0 || numValues[2] > 59));

        }
        if (validation === 'range') {
            const intVal = parseInt(value, 10);
            if (opt) {
                return  (intVal <= opt.max) && (intVal >= opt.min)
            }
            return false;
        }
    }

    const adjustValues = (value, validation) => {
        if (validation === "hh:mm:ss" && value.length === 7) {
            return `0${value}`
        } else {
            return value
        }
    }

    useEffect(_ => {
        const checkDataType = (data) => {
            if (
                data.filter(row => row.length === 1).length === data.length
            ) {
                setCsvType('list')
                return 'list'
            } else {
                setCsvType('table');
                return 'table'
            }
        }

        setUploadCompleted(false);
        setIsSending(false);
        setError(false);
        setErrorText('');
        if (csvData.length > 0) {
            if (checkDataType(csvData) === 'table') {
                const cols = csvData[0].filter(el => el.length !== 0);
                const data = csvData.slice(1).filter(row => row.filter(el => el.length !== 0).length !== 0);
                const generatedCols = [];
                const mandatoryFields = []
                let indexMediaUrl = -1;
                const tableCols = [{
                    field: 'id',
                    headerName: 'N°',
                    width: 80,
                }];
                let hasMediaURl = false;
                setError(false)
                try {
                    if (!!detectMultipleValidation && Object.keys(detectMultipleValidation).length > 0) {
                        detectMultipleValidation.forEach((el, index) => {
                            if (!!el['mandatory']) {
                                mandatoryFields.push(el.name);
                            }
                        })
                    }

                    cols.forEach(colName => {
                        const index = mandatoryFields.indexOf(colName);
                        if (index > -1) {
                            mandatoryFields.splice(index, 1);
                        }

                        const cleanedColName = colName.replace(/[^A-Z0-9]/ig, "").toLowerCase();
                        if (cleanedColName === 'mediaurl') {
                            hasMediaURl = true;
                            indexMediaUrl = index;
                            tableCols.push({
                                field: 'mediaUrl',
                                headerName: colName,
                                width: 150,
                                renderCell: (params) => {
                                    const {row} = params;
                                    const val = row['mediaUrl'];
                                    return (
                                        <a href={val} target={'_blank'}>
                                            {val}
                                        </a>
                                    )
                                }
                            });
                            generatedCols.push('mediaUrl')
                        } else {
                            tableCols.push({
                                field: colName,
                                headerName: colName,
                                width: 150,
                            });
                            generatedCols.push(colName);

                        }

                    })

                    if (mandatoryFields.length > 0) {
                        throw 'Heading/s Missing Or Misspelled';
                    }

                    if (!hasMediaURl) throw `Heading 'mediaUrl' Missing Or Misspelled`;

                    const rows = data.map((row, index) => {
                        const cells = generatedCols
                            .map((col, colIndex) => {
                                let value = (!!row[colIndex] || row[colIndex] !== 0) ? String(row[colIndex]).trim()  : ''
                                const rule = detectMultipleValidation.find(el => el.name === col);
                                if (!!rule) {
                                    const isMandatory = !!rule['mandatory'];
                                    const rangeValidation = !!(rule['validationMax'] && rule['validationMin'])
                                    const hasValidation = !!rule['validation'] || rangeValidation;
                                    if (hasValidation) {
                                        if (isMandatory || value.length > 0) {
                                            if (
                                                !validateField(value, rule['validation'] ?? 'range', rangeValidation? {
                                                    min: rule.validationMin,
                                                    max: rule.validationMax,
                                                }: null)
                                            ) {
                                                throw `Cell did not pass validation. Error on ${value}, line ${index + 1}`
                                            } else {
                                                value = adjustValues(value, rule['validation']);
                                            }
                                        }
                                    }
                                }
                                const obj = {};
                                obj[col] = value;
                                return obj
                            })
                            .reduce(function (result, current) {
                                return Object.assign(result, current);
                            }, {})

                        return {
                            ...cells,
                            id: index + 1,
                        }
                    })

                    setHeader(tableCols);
                    setRowCsv(rows);
                } catch (e) {
                    setErrorText(e);
                    setError(true)
                }

            } else {

            }
        }


    }, [csvData, open])


    const submitCsv = () => {
        setIsSending(true)
        const dataToSend = [];
        if (csvType === 'list') {
            csvData.flat().forEach(el => {
                dataToSend.push({
                    mediaUrl: el,
                })
            })
        } else {
            rowCsv.forEach(el => {
                const rowCopy = el;
                delete rowCopy.id;
                const obj = {
                    mediaUrl: el.mediaUrl
                };
                delete rowCopy.mediaUrl;
                const meta = {};
                Object.keys(rowCopy).forEach(key => {
                    const rule = detectMultipleValidation.find(el => el.name === key);
                    const value = el[key];
                    if (String(value).length !== 0 || !!rule.includeBlankValues) {
                        if (!!rule && !!rule['bodyParam']) {
                            obj[rule['bodyParam']] = el[key];
                            if (!!rule['includeInMeta']) {
                                meta[key] = el[key]
                            }
                        } else {
                            meta[key] = el[key]
                        }
                    }
                });
                obj['meta'] = meta;
                dataToSend.push(obj)
            })
        }


        console.log(dataToSend);

        DataService.postDetectMultipleJson(dataToSend)
            .catch(err => {
                console.error(err);
                setError(true);
            })
            .finally(() => {
                setUploadCompleted(true);
                setIsSending(false);
            })
        ;
    }


    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth
            open={open}
            onClose={(event, reason) => {
                return;
            }}
            disableEscapeKeyDown={true}
        >
            <DialogTitle sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <span>CSV Preview</span>
                <IconButton
                    onClick={_ => handleClose()}
                    sx={{ml: 'auto'}}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            {
                uploadCompleted &&
                <>
                    <DialogContent
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        {
                            error ?
                                <DangerousIcon
                                    color={'error'}
                                    sx={{
                                        mt: 4,
                                        fontSize: '4rem',
                                    }}
                                />
                                :
                                <CloudDoneIcon
                                    color={'success'}
                                    sx={{
                                        mt: 4,
                                        fontSize: '4rem',
                                    }}
                                />
                        }

                        <Typography
                            variant={'h4'}
                            color={error ? 'error.main' : 'success.main'}
                        >
                            Uploaded {error ? "Failed" : "Completed"}
                        </Typography>
                        <Typography
                            variant={'h6'}
                        >
                            {!error ? "The upload was successful. You can close this window Now"
                                : "The upload was unsuccessful. You can close this window and try again"}
                        </Typography>
                    </DialogContent>
                    <DialogActions
                        sx={{
                            borderTop: '1px solid #ccc',
                            py: 2,
                            px: 3,
                        }}

                    >
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            autoFocus
                            onClick={_ => handleClose()}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </>
            }
            {
                !uploadCompleted &&
                <>
                    <DialogContent
                        ref={containerTable}
                    >
                        {
                            (isLoading || isSending)
                                ?
                                <Box
                                    sx={{
                                        margin: '2em auto',
                                        textAlign: 'center',
                                    }}
                                >
                                    <CircularProgress
                                        size={'4em'}
                                    />
                                    <Typography
                                        variant={'h3'}
                                    >
                                        {
                                            isSending
                                                ? "We are submitting the data..."
                                                : "We are loading the preview table..."
                                        }
                                    </Typography>
                                </Box>
                                :
                                <Box>


                                    {
                                        csvType === 'list' ?
                                            <ListOfUrls
                                                data={csvData.flat()}
                                            />
                                            : <PreviewCSVTable
                                                cols={header}
                                                data={rowCsv}
                                                error={error}
                                                errorText={errorText}
                                                parent={containerTable}
                                            />
                                    }

                                </Box>
                        }

                    </DialogContent>
                    <DialogActions
                        sx={{
                            borderTop: '1px solid #ccc',
                            py: 2,
                            px: 3,
                        }}

                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                            }}
                        >
                            <Box
                                sx={{
                                    color: 'error.main',
                                    textAlign: 'right'
                                }}
                            >
                                <Typography
                                    variant={'body1'}
                                >
                                    <strong>Attention</strong>: Please check the data above carefully before pressing
                                    ‘Submit’ as this action cannot be undone and media will be processed as submitted.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    color: 'error.main',
                                    textAlign: 'right',
                                    ml: 1,
                                }}
                            >
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    disabled={isSending || error}
                                    onClick={_ => {
                                        submitCsv()
                                    }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </DialogActions>

                </>
            }


        </Dialog>
    )
}

PreviewCSVModal.propTypes = {}

export default PreviewCSVModal
